import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../pages/Home";
import Page404 from "../pages/Page404";
import { PageProps } from "../types";

export default function Routing(props: PageProps) {
  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "/",
          element: <Home {...props} />,
        },
        {
          path: "/*",
          element: <Page404 />,
        },
      ])}
    />
  );
}
