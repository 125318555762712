import { CandyMachineId, Host, Network } from "../../types/Entities/CandyMachine";
import { environment } from "../../environments/environment";

class CandyMachine {
  private candyMachineId: CandyMachineId | undefined;
  private network: Network | undefined;
  private host: Host | undefined;

  set(candyMachineId: CandyMachineId, network: Network): void {
    this.candyMachineId = candyMachineId;
    this.network = network;
    this.host = this.updateHost();
  }

  getId(): CandyMachineId | undefined {
    return this.candyMachineId;
  }

  getNetwork(): Network | undefined {
    return this.network;
  }

  getHost(): Host | undefined {
    return this.host;
  }

  private updateHost() {
    switch (this.network) {
      case Network.DEVNET:
        return environment[Network.DEVNET].rpc;
      case Network.TESTNET:
        return environment[Network.TESTNET].rpc;
      case Network.MAINNET_BETA:
        return environment[Network.MAINNET_BETA].rpc;
      default:
        return undefined;
    }
  }
}

export default new CandyMachine();
