import { Network } from "../types/Entities/CandyMachine";

export const environment = {
  [Network.DEVNET]: {
    rpc: "https://api.devnet.solana.com",
  },
  [Network.TESTNET]: {
    rpc: "https://api.testnet.solana.com",
  },
  [Network.MAINNET_BETA]: {
    rpc: "https://tame-special-general.solana-mainnet.discover.quiknode.pro/27e3188056eb542ce60e2264298ba46284843fdc",
  },
};
