import "./App.css";
import { useEffect, useMemo } from "react";
import * as anchor from "@project-serum/anchor";
import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { createTheme, ThemeProvider } from "@mui/material";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import {
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
} from "@solana/wallet-adapter-sollet";
import Routing from "./routing";
import CandyMachine from "./services/CandyMachine"
import { Network } from "./types/Entities/CandyMachine";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(CandyMachine.getId()!);
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

let error: string | undefined = undefined;

const App = () => {
  const search = window?.location?.search;
  const _id = search.match(/candy-machine-id=([^&]*)/) || [];
  const _use = search.match(/use=([^&]*)/) || [];
  const id = _id.map(el => decodeURI(el));
  const use = _use.map(el => decodeURI(el));
  CandyMachine.set(id[1], use[1] as Network);

  useEffect(() => {
    if (CandyMachine.getNetwork() === undefined) {
      error =
        "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
    } else if (CandyMachine.getHost() === undefined) {
      error =
        "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
    }
  }, [])

  const candyMachineId = getCandyMachineId();
  const network = (CandyMachine.getNetwork() ?? "mainnet-beta") as WalletAdapterNetwork;
  const rpcHost = CandyMachine.getHost() ?? anchor.web3.clusterApiUrl("mainnet-beta");
  const connection = new anchor.web3.Connection(rpcHost);

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new SlopeWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Routing
              candyMachineId={candyMachineId}
              connection={connection}
              txTimeout={DEFAULT_TIMEOUT}
              rpcHost={rpcHost}
              network={network}
              error={error}
            />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
